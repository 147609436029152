import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VList,[_c(VListItem,{staticClass:"account-name",attrs:{"to":{ name: 'account-settings' }}},[_c(VListItemAvatar,{staticClass:"lighten-1",attrs:{"color":"primary"}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-account-circle")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.user.first_name)+" "+_vm._s(_vm.user.last_name)+" ")]),_c(VListItemSubtitle,[_c('i',[_vm._v(_vm._s(_vm.user.username))])])],1)],1)],1),_c(VDivider),_vm._l((_vm.menuItemsForUser),function(group){return _c('div',{key:group.groupName},[_c(VList,{attrs:{"nav":"","dense":""}},[(group.groupName && group.showGroupName)?_c(VSubheader,[_vm._v(" "+_vm._s(group.groupName)+" ")]):_vm._e(),_vm._l((group.items),function(item){return _c(VListItem,{key:item.name,attrs:{"to":item.to || { name: item.name },"link":"","exact":""}},[(item.icon)?_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1):_vm._e(),_c(VListItemContent,[_vm._v(_vm._s(item.display))])],1)})],2),_c(VDivider)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }