import Vue from "vue";
import VuetifyConfirm from "vuetify-confirm";
import Notifications from "vue-notification";

import "@/lib/prototypes";

import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import utils from "@/lib/mixins/utils";
import localStorage from "@/lib/mixins/localStorage";

Vue.config.productionTip = false;

Vue.use(require("vue-moment"));
Vue.use(Notifications);
Vue.use(VuetifyConfirm, { vuetify });

Vue.mixin(utils);
Vue.mixin(localStorage);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
