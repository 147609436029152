import Vue from "vue";
import { apiCall } from "@/lib/api";

export default {
  strict: true,
  state: {
    selectedArticles: {},
  },
  mutations: {
    setSelectedArticles(state, selectedArticles) {
      state.selectedArticles = selectedArticles;
    },
    addArticle(state, article) {
      Vue.set(state.selectedArticles, article._id, article);
    },
    removeArticle(state, id) {
      Vue.delete(state.selectedArticles, id);
    },
  },
  actions: {
    async loadSelectedArticles(context) {
      try {
        const response = await apiCall("GET", "/saved-articles/");
        const articles = response
          .map((content) => content.article)
          .reduce((rest, article) => ({ ...rest, [article._id]: article }), {});
        if (articles) context.commit("setSelectedArticles", articles);
      } catch (e) {
        // pass
      }
    },
    async addArticle(context, article) {
      await apiCall(
        "POST",
        "/saved-articles/",
        {},
        {
          article: article,
        }
      );
      context.commit("addArticle", article);
    },
    async removeArticle(context, id) {
      await apiCall("DELETE", `/saved-articles/${id}/`);
      context.commit("removeArticle", id);
    },
  },
  getters: {
    selectedArticles(state) {
      return state.selectedArticles;
    },
    selectedArticleList(state) {
      return Object.values(state.selectedArticles);
    },
    selectedArticleCount(state) {
      return Object.keys(state.selectedArticles).length;
    },
  },
};
