(function () {
  "use strict";

  function escapeHTML() {
    return this.replace(/[&<>"'/()]/g, function (s) {
      const entityMap = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#39;",
        "/": "&#x2F;",
        "(": "&#40;",
        ")": "&#41;",
      };
      return entityMap[s];
    });
  }

  function toTitleCase() {
    if (this.length <= 0) return this;
    return this[0].toUpperCase() + this.slice(1).toLowerCase();
  }

  if (typeof String.prototype.escapeHTML !== "function") {
    String.prototype.escapeHTML = escapeHTML;
  }

  if (typeof String.prototype.toTitleCase !== "function") {
    String.prototype.toTitleCase = toTitleCase;
  }
})();
