<template>
  <v-dialog v-if="globalLoading" persistent width="300" value="true">
    <v-card color="primary" dark class="pa-5">
      <v-card-text>
        <p>Please stand by</p>
        <v-progress-linear indeterminate color="white" class="mb-0" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GlobalLoader",
  computed: {
    ...mapGetters(["globalLoading"]),
  },
};
</script>

<style scoped></style>
