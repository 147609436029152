<template>
  <div>
    <v-list>
      <v-list-item :to="{ name: 'account-settings' }" class="account-name">
        <v-list-item-avatar color="primary" class="lighten-1">
          <v-icon dark> mdi-account-circle</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ user.first_name }} {{ user.last_name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <i>{{ user.username }}</i>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider />
    <div v-for="group in menuItemsForUser" :key="group.groupName">
      <v-list nav dense>
        <v-subheader v-if="group.groupName && group.showGroupName">
          {{ group.groupName }}
        </v-subheader>
        <v-list-item
          v-for="item in group.items"
          :key="item.name"
          :to="item.to || { name: item.name }"
          link
          exact
        >
          <v-list-item-icon v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ item.display }}</v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

const menuItems = [
  {
    groupName: "search",
    displayOnMobile: true,
    items: [
      {
        name: "search",
        display: "Search",
        icon: "search",
        displayOnMobile: true,
      },
      {
        name: "report-editor",
        display: "Report Editor",
        icon: "chrome_reader_mode",
      },
      {
        name: "dashboard",
        display: "Dashboard",
        icon: "dashboard",
      },
      {
        name: "trending",
        display: "Trending Terms",
        icon: "insights",
        displayOnMobile: true,
      },
      {
        name: "suggest-sources",
        display: "Suggest Source",
        icon: "library_add",
      },
      {
        name: "knowledge-graph",
        display: "Knowledge Graph",
        icon: "mdi-graph",
      },
      {
        name: "saved-searches",
        display: "Saved Searches",
        icon: "mdi-bookmark",
      },
    ],
  },
  {
    groupName: "Third-Party",
    showGroupName: false,
    displayOnMobile: true,
    superUser: false,
    items: [
      {
        name: "cve-explorer",
        display: "CVE Explorer",
        icon: "mdi-shield-bug",
        displayOnMobile: true,
      },
      {
        name: "cve-map",
        display: "Shodan Map",
        icon: "mdi-map-search",
        shodanPowerUser: true,
        displayOnMobile: true,
      },
    ],
  },
  {
    groupName: "Collaboration",
    showGroupName: false,
    superUser: false,
    displayOnMobile: true,
    items: [
      {
        name: "collaborators",
        display: "Collaborators",
        icon: "people",
      },
      {
        name: "share",
        display: "Shared",
        icon: "share",
        displayOnMobile: true,
      },
    ],
  },
  {
    groupName: "Sources",
    showGroupName: true,
    superUser: true,
    items: [
      {
        name: "import-source",
        display: "Import Sources",
      },
      {
        name: "manage-sources",
        display: "Manage Sources",
      },
      {
        name: "suggested-sources",
        display: "Suggested Sources",
        to: {
          name: "manage-sources",
          query: {
            status: "pending",
            showFilters: "true",
          },
        },
      },
    ],
  },
  {
    groupName: "Admin",
    showGroupName: true,
    superUser: true,
    items: [
      {
        name: "audit-log",
        display: "Audit log",
      },
      {
        name: "manage-users",
        display: "Manage Users",
      },
      {
        name: "manage-proxies",
        display: "Manage Proxies",
      },
      {
        name: "manage-tlp-green",
        display: "Manage TLP Green",
      },
    ],
  },
  {
    groupName: "Account",
    showGroupName: true,
    displayOnMobile: true,
    items: [
      {
        name: "account-settings",
        display: "Settings",
      },
      {
        name: "user-manual",
        display: "User Manual",
        displayOnMobile: true,
      },
      {
        // used to force refresh of the main component,
        // to use the routerview named login, instead of the main routerview
        to: "/account/logout",
        display: "Log out",
        displayOnMobile: true,
      },
    ],
  },
];
export default {
  name: "Sidebar",
  computed: {
    ...mapGetters(["user"]),
    menuItemsForUser() {
      return menuItems.filter(this.filterMenuGroups).map((group) => {
        return {
          ...group,
          items: group.items.filter(this.filterMenuItems),
        };
      });
    },
  },
  methods: {
    filterMenuItems(item) {
      let filteredItem = true;
      if (this.$vuetify.breakpoint.xs) {
        filteredItem = item.displayOnMobile;
      }
      return (
        (!item.shodanPowerUser || this.user.is_shodan_power_user) &&
        (!item.superUser || this.user.is_superuser) &&
        filteredItem
      );
    },
    filterMenuGroups(group) {
      let filteredGroup = true;
      if (this.$vuetify.breakpoint.xs) {
        filteredGroup = group.displayOnMobile;
      }
      return (!group.superUser || this.user.is_superuser) && filteredGroup;
    },
  },
};
</script>
