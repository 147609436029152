import router from "@/router";
import { apiCall } from "@/lib/api";

/**
 * Zip two arrays.
 *
 * @param array1 {Array}
 * @param array2 {Array}
 * @return {Array<Array>}
 */
export function zip(array1, array2) {
  return array1.map((item, index) => {
    return [item, array2[index]];
  });
}

/**
 * Parse options object from a v-data-table, and return
 * query params for the API
 *
 * @param options {Object}
 * @return {Object}
 */
export function getAPIListParams(options) {
  return {
    offset: (options.page - 1) * options.itemsPerPage,
    limit: options.itemsPerPage,
    ordering: zip(options.sortBy, options.sortDesc)
      .map(([sortField, desc]) => (desc ? "-" : "") + sortField)
      .join(","),
  };
}

function getRouteName(item) {
  switch (item.type) {
    case "friend_request":
      return "collaborators";
    case "search_results":
      return "saved-searches";
    case "article_share":
    case "cve_share":
      return "share";
    default:
      return "notifications";
  }
}

function getRouteQuery(item) {
  if (item.metadata && item.metadata.share_id) {
    const activeTab = item.metadata.to_group ? 2 : 0;
    return { ID: item.metadata.share_id, activeTab: activeTab };
  }
}

export function getNotificationRoute(item) {
  return { name: getRouteName(item), query: getRouteQuery(item) };
}

/**
 * Create a new Notification and set the click handler to send the
 * user to the corresponding page and mark the notification as read.
 *
 * @param item {Object} Notification serialized from the backend
 */
export function createDesktopNotification(item) {
  const routeHref = router.resolve(getNotificationRoute(item)).href;

  let body = "New " + item.type.replace("_", " ");
  if (item.sender) {
    body += " from " + item.sender;
  }
  const notification = new Notification("New notification from OpenCSAM", {
    body: body,
    requireInteraction: true,
    renotify: true,
    tag: item.id,
    icon: "/logo-small-bg.png",
    timestamp: Math.floor(new Date(item.created)) || undefined,
  });
  notification.addEventListener(
    "click",
    (event) => {
      event.preventDefault();
      apiCall("POST", `/notifications/${item.id}/mark_as_read/`);
      window.open(routeHref, "_blank");
    },
    { once: true }
  );
}
