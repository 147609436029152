import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const enisaColor = {
  primary: "#004f9f",
  secondary: "#cb0538",
  accent: "#5bc5f2",
  error: "#ee756d",
  info: "#5bc5f2",
  success: "#95c11f",
  warning: "#f9b233",
  // Palette (Pantone)
  orange: "#f9b233",
  green: "#95c11f",
  red: "#cb0538",
  blue: "#7197cf",
  cyan: "#5bc5f2",
  purple: "#70147A",
};

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        ...enisaColor,
        anchor: enisaColor.primary,
      },
      dark: {
        ...enisaColor,
        anchor: enisaColor.accent,
      },
    },
  },
  icons: {
    iconfont: "md",
  },
});
