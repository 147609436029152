import Vue from "vue";
import Vuex from "vuex";
import user from "@/store/modules/user";
import report from "@/store/modules/report";
import notifications from "@/store/modules/notifications";
import appSettings from "@/store/modules/appSettings";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {
    globalLoading: false,
  },
  mutations: {
    setGlobalLoading(state, value) {
      state.globalLoading = value;
    },
  },
  actions: {
    async initStore(context) {
      await Promise.all([
        context.dispatch("getCurrentUser"),
        context.dispatch("loadSelectedArticles"),
        context.dispatch("getUnreadNotificationsCount"),
      ]);
    },
    async reloadNotifications(context) {
      if (!context.getters.isAuth) return;
      await context.dispatch("getUnreadNotificationsCount");
    },
  },
  getters: {
    globalLoading(state) {
      return state.globalLoading;
    },
  },
  modules: {
    user,
    report,
    notifications,
    appSettings,
  },
});
