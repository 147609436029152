export default {
  methods: {
    /**
     * Checks if two sets are equal
     *
     * @param set1 {Set}
     * @param set2 {set}
     * @return Boolean
     */
    equalSets(set1, set2) {
      return (
        set1.size === set2.size && Array.from(set1).every((el) => set2.has(el))
      );
    },
    /**
     * Check if the string is a integer.
     *
     * @param str {String}
     * @return {boolean}
     */
    isInteger(str) {
      // XXX This allows leading 0s
      return /^-?\d+$/.test(str);
    },
    /**
     * Check if string is a float.
     *
     * @param str {String}
     * @return {boolean}
     */
    isFloat(str) {
      // XXX This allows leading 0s
      return /^-?\d+(.\d+)?$/.test(str);
    },
    /**
     * Format a float into a string with the specified number of digits after the
     * decimal. Avoids -0 values.
     *
     * @param num {number}
     * @param digits {number}
     * @returns {string}
     */
    formatFloat(num, digits) {
      if (num === null || num === undefined) return num;

      let value = parseFloat(num).toFixed(digits);
      if (parseFloat(value) === 0) {
        value = (0).toFixed(digits);
      }
      return value;
    },
    /**
     * Format a Date object to "YYYY-MM-DD"
     *
     * @param date {Date}
     * @returns {string}
     */
    formatDate(date) {
      if (!date) return "";
      return date.toISOString().slice(0, 10);
    },
    /**
     * Format a Datetime object to "YYYY-MM-DD HH:mm:ss"

     * @param date {Date}
     * @returns {string}
     */
    formatDateTime(date) {
      if (!date) return "";
      return date.toISOString().replace("T", " ").slice(0, 19);
    },
    resetErrors: function () {
      Object.keys(this.errors).forEach((key) => (this.errors[key] = []));
    },
    setErrors: function (reason) {
      if (reason.details) {
        if (typeof reason.details === "string") {
          this.errors.error = reason.details;
        } else {
          Object.entries(reason.details).forEach((entry) => {
            this.errors[entry[0]] = entry[1];
          });
        }
      } else if (reason.detail || reason.error) {
        this.errors.error = reason.detail || reason.error;
      } else if (typeof reason === "object") {
        Object.entries(reason).forEach(([key, messages]) => {
          this.errors[key] = messages;
        });
      } else {
        this.errors.error = "Unknown error.";
      }
    },
    numberToHumanString: function (number) {
      // inspired by https://github.com/cerberus-ab/human-readable-numbers
      // known SI prefixes, multiple of 3
      var PREFIXES = {
        24: "Y",
        21: "Z",
        18: "E",
        15: "P",
        12: "T",
        9: "G",
        6: "M",
        3: "k",
        0: "",
        "-3": "m",
        "-6": "µ",
        "-9": "n",
        "-12": "p",
        "-15": "f",
        "-18": "a",
        "-21": "z",
        "-24": "y",
      };

      function getExponent(n) {
        if (n === 0) {
          return 0;
        }
        return Math.floor(Math.log10(Math.abs(n)));
      }

      function precise(n) {
        return Number.parseFloat(n.toPrecision(3));
      }

      if (number === undefined) {
        return undefined;
      }

      var n = precise(Number.parseFloat(number));
      var e = Math.max(Math.min(3 * Math.floor(getExponent(n) / 3), 24), -24);
      return precise(n / Math.pow(10, e)).toString() + PREFIXES[e];
    },
  },
};
