import { apiCall } from "@/lib/api";

export default {
  strict: true,
  state: {
    unreadNotificationsCount: 0,
  },
  mutations: {
    setUnreadNotificationsCount(state, unreadNotificationsCount) {
      state.unreadNotificationsCount = unreadNotificationsCount;
    },
  },
  actions: {
    async getUnreadNotificationsCount(context) {
      const params = { status: "unread", limit: 1 };
      let response = await apiCall("GET", "/notifications/", params);
      context.commit("setUnreadNotificationsCount", response.count);
    },
  },
  getters: {
    unreadNotificationsCount(state) {
      return state.unreadNotificationsCount;
    },
  },
};
