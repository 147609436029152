import { apiCall } from "@/lib/api";

export default {
  strict: true,
  state: {
    appSettings: {
      auth_azure_enabled: false,
      sentry_dsn: null,
      sentry_env: null,
      sentry_release: null,
    },
  },
  mutations: {
    setAppSettings(state, appSettings) {
      state.appSettings = appSettings;
    },
  },
  actions: {
    async getAppSettings(context) {
      const response = await apiCall("GET", "/app_settings/");
      context.commit("setAppSettings", response);
    },
  },
  getters: {
    appSettings(state) {
      return state.appSettings;
    },
  },
};
