import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"app"}},[(!_vm.isAuth || _vm.$route.name === 'account-logout')?_c('div',[_c('global-loader'),(_vm.loaded)?_c('div',[_c('login')],1):_vm._e()],1):_c('div',[_c('global-loader'),_c('notifications',{attrs:{"position":"top center","classes":"opencsam-notification","ignore-duplicates":"","max":2,"width":"450"}}),_c('toolbar'),(_vm.loaded && !_vm.error)?_c(VMain,{staticClass:"lighten-5 justify-center"},[_c('router-view')],1):_c(VMain,{staticClass:"lighten-5 justify-center align-center"},[(!_vm.loaded)?_c(VCard,{staticClass:"ma-5"},[_c(VCardTitle,[_vm._v(" Loading OpenCSAM ")]),_c(VCardText,{staticClass:"text-center"},[_c(VProgressCircular,{staticClass:"my-10",attrs:{"indeterminate":"","size":"100","color":"primary"}})],1)],1):_vm._e(),(_vm.error)?_c(VCard,{staticClass:"ma-5"},[_c(VCardTitle,{staticClass:"elevation-8 error"},[_c(VIcon,{attrs:{"left":""}},[_vm._v("error")]),_vm._v(" "+_vm._s(_vm.error)+" ")],1)],1):_vm._e()],1),_c(VFooter,{attrs:{"color":"transparent","app":"","absolute":""}},[_c('div',{staticClass:"d-flex align-center justify-end flex-grow-1"},[_c('span',[_vm._v("ENISA is an agency of the European Union")]),_c('img',{staticClass:"ml-2",attrs:{"src":require("@/assets/img/eu-flag.png"),"alt":"EU-flag"}})])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }